import { Formik, withFormik } from 'formik'
import * as Yup from 'yup'
import React from 'react'
import { Link, graphql, navigate } from 'gatsby'
import styled, { css } from 'react-emotion'
import Layout from '../components/layout'
import Img from 'gatsby-image'
import Seo from '../components/Seo'
import { mq, colors, PageHeadings } from '../utils/styles'
import { post } from 'axios'
import Socials from '../components/Socials'

const contactUrl =
  'https://static-runningdev.herokuapp.com/financeforhippies/contact'

const SuccessMessage = styled('span')`
  padding-left: 1rem;
  color: ${colors.green};
`

const ErrorMessage = styled('span')`
  padding-left: 1rem;
  color: ${colors.red};
`

const EmailSubmitButton = styled('button')`
  font-family: 'Pangolin', cursive;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  border-radius: 0.25rem;
  color: #fff;
  margin-top: 1rem;
  background-color: ${colors.red};
  cursor: pointer;
  // font-size: 0.8rem;
  line-height: 1.15;
  &:hover {
    background-color: ${colors.lightRed};
  }
`
const EmailInput = css`
  // width: 100%;
  // max-width: 20rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-family: 'Pangolin', cursive;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  border-width: 1px;
  border-radius: 0.25rem;
  line-height: 1.15;
  // margin: 0;
  display: block;
`

const textInputErrorStyle = css`
  // padding-left: 0.5rem;
  // padding-right: 0.5rem;
  // padding-top: 0.25rem;
  // padding-bottom: 0.25rem;
  // border-radius: .25rem;
  // width: 30rem;
  // background-color: #f1f5f8;
  // appearance: none;
  // overflow: visible;
  // display: block;
  // border: 0 solid #dae1e7;

  // width: 100%;
  // max-width: 20rem;
  font-family: 'Pangolin', cursive;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  border-width: 1px;
  border-radius: 0.25rem;
  line-height: 1.15;
  margin: 0;
  display: block;
`

const errorMessageStyle = css`
  margin-top: 0.4rem;
  color: #cc1f1a;
  font-size: 0.7rem;
`

class Contact extends React.Component {
  render() {
    return (
      <Layout location={this.props.location}>
        <Seo
          type="page"
          title="Contact"
          description="Contact us for sponsorship opportunities or if you would like to speak on the podcast"
          path={'/contact/'}
        />
        <div
          className={css`
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
          `}
        >
          <Img fixed={this.props.data.art.childImageSharp.fixed} />
          <div
            className={css`
              padding-left: 2rem;
              max-width: 32rem;
            `}
          >
            <PageHeadings
              className={css`
                visibility: hidden;
                height: 0rem;
                margin: 0;
              `}
            >
              Contact Us
            </PageHeadings>
            <p>
              Contact us about sponsorship opportunities to get your brand
              mentioned on the podcast.
            </p>
            <p>
              If you would like to speak on the podcast or would like to suggest
              someone please send us an email.
            </p>
            <div
              className={css`
                margin-top: 1rem;
              `}
            >
              <div className={css`
                margin-bottom: 1rem;
              `}>financeforhippies@gmail.com</div>

              <Socials feed={this.props.data.site.siteMetadata.podcastFeed} />
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default Contact

export const pageQuery = graphql`
  query ContactQuery {
    site {
      siteMetadata {
        siteName
        siteUrl
        podcastFeed
      }
    }
    art: file(
      sourceInstanceName: { eq: "assets" }
      relativePath: { eq: "poster.png" }
    ) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        # cropFocus: CENTER
        fixed(width: 300, height: 370) {
          ...GatsbyImageSharpFixed_tracedSVG
        }
      }
    }
  }
`
